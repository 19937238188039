import React from "react"
import { StoryGroup } from "components"
import { flatStoryGroup } from "../utils/array-util"

const eBooks = [
  {
    title: "Let's Develop a Simple Calculator",
    url: "https://learnreactui.dev/contents/lets-develop-a-simple-calculator",
    img:
      "https://storage.googleapis.com/argon-app-web-prod/assets%2F3268eca6-502b-4280-8a2e-d7266eb19f6c%2Fcovers%2FSC_Cover3.png",
  },
  {
    title: "AI Chat Sample",
    url: "https://learnreactui.dev/contents/ai-chat-sample",
    img:
      "https://storage.googleapis.com/argon-app-web-prod/assets%2Fb08d480a-c86d-4bef-b333-39aaaa03e63e%2Fcovers%2FCover.png",
  },
  {
    title: "Kanban Board Sample",
    url: "https://learnreactui.dev/contents/kanban-board",
    img:
      "https://storage.googleapis.com/argon-app-web-prod/assets%2F2b134aad-5755-4a01-a798-0e578ef058ce%2Fcovers%2FCover.png",
  },
]

const LinkMap = {
  "React Setup": "c2eba4e5d30f",
  "React Setup Detail": "b9a7d9249fd6",
  "React Env": "fee4c8c095dc",
  "React Deployment": "294479d1ee9e",
  "State And Props": "a2e80f6fe783",
  "Function Component": "9d0a3c188c7d",
  "Class Component": "9d0a3c188c7d",
  "Hook Component": "9d0a3c188c7d",
  Navigation: "389107fec4fd",
  "Communication Two Comp": "abda7e328e98",
  "Communication Over Redux": "756125ba62db",
  "Communication Server": "7e6b25611674",

  "Analysis & Design": "a4f67f6721dd",
  Calculator: "30194e8338eb",
  "Calculator Styling": "60047eeeca24",

  "AdminTool-Concepts": "3bd6bc26503c",
  Login: "9f63e20b1d88",
  CRUD: "45a6abb25069",
  "Table Pagination": "608026aaeef0",
  "Table Search And Sorting": "ecbad87dbba2",
  "Table Multiple Row Selection": "d39ccf38be39",
  "Navigation Menu": "366be90d64e2",
  "Collapsible Table": "1cbf4677c204",
  "Dashboard Page": "8a3567b3afd1",
  Warnings: "9b9cc3585e34",
  "Organization Structure": "56abdafa64",
  "Update Organization": "5a8dffedbc22",
  "Select From List": "67bbadc3b5cc",
  "Drag-Drop": "bba3eab066b9",
  "Calendar Schedule": "7e5d427b4c27",

  Responsive: "e929a082a0ed",
  "Media Query": "e929a082a0ed",
  "Viewport Unit": "e929a082a0ed",
}

const storiesBasicUIMechanics = [
  {
    title: "React Geliştirme Ortamının Oluşturulması",
    postId: "c2eba4e5d30f",
  },
  {
    title: "React Geliştirme Ortamının Oluşturulması- (Detaylı)",
    postId: "b9a7d9249fd6",
  },
  {
    title: "React Farklı Ortamlar (Dev, Production, vb…)",
    postId: "fee4c8c095dc",
  },
  {
    title: "React Deployment (Local, AWS, Netlify ve Vercel)",
    postId: "294479d1ee9e",
  },
  {
    title: "React Bileşenlerinde State ve Props",
    postId: "a2e80f6fe783",
  },
  {
    title: "React Component Types",
    postId: "9d0a3c188c7d",
  },
  {
    title: "React’da Sayfalar Arası Navigasyon",
    postId: "389107fec4fd",
  },
  {
    title: "React 2 Bileşeni Konuşturalım",
    postId: "abda7e328e98",
  },
  {
    title: "React Virtual DOM 2 Uzak Bileşeni Konuşturalım",
    postId: "756125ba62db",
  },
  {
    title: "React ile Rest API Cağırımı",
    postId: "7e6b25611674",
  },
]

const storiesCalculatorMechanics = [
  {
    title: "Planlama, Analiz ve Tasarım.",
    postId: "a4f67f6721dd",
  },
  {
    title: "Hesap Makinesi Geliştirelim",
    postId: "30194e8338eb",
  },
  {
    title: "Hesap Makinesi Stilllendirme",
    postId: "60047eeeca24",
  },
  {
    title: "Hesap Makinesi Responsive Tasarlama",
    postId: "e929a082a0ed",
  },
]

const storiesAdminToolUIMechanics = [
  {
    title: "React ile Admin Tool-0 (Kapsam)",
    postId: "3bd6bc26503c",
  },
  {
    title: "React ile Admin Tool-1 (Login)",
    postId: "9f63e20b1d88",
  },
  {
    title: "React ile Admin Tool-2 (CRUD)",
    postId: "45a6abb25069",
  },
  {
    title: "React ile Admin Tool-3 (Table Pagination)",
    postId: "608026aaeef0",
  },
  {
    title: "React ile Admin Tool-4 (Table Search & Sorting)",
    postId: "ecbad87dbba2",
  },
  {
    title: "React ile Admin Tool-5 (Multiple Row Selection)",
    postId: "d39ccf38be39",
  },
  {
    title: "React ile Admin Tool-6 Navigasyon Menüsü",
    postId: "366be90d64e2",
  },
  {
    title: "React ile Admin Tool-7 Collapsible Table",
    postId: "1cbf4677c204",
  },
  {
    title: "React ile Admin Tool-8 Dashboard Page",
    postId: "8a3567b3afd1",
  },
  {
    title: "React ile Admin Tool-9 Uyarı ve Hatalar",
    postId: "9b9cc3585e34",
  },
  {
    title: "React ile Admin Tool-10 Organizayon Yapısı",
    postId: "56abdafa64",
  },
  {
    title: "React ile Admin Tool-11 Organizayonu Güncelle",
    postId: "5a8dffedbc22",
  },
  {
    title: "React ile Admin Tool-12 Listede Seçim",
    postId: "67bbadc3b5cc",
  },
  {
    title: "React ile Admin Tool-13 Drag-Drop",
    postId: "bba3eab066b9",
  },
  {
    title: "React ile Admin Tool-14 Calendar-Schedule",
    postId: "7e5d427b4c27",
  },
]

const storiesMiniAppMechanics = [
  {
    title: "Task Management Mini App",
    postId: "9452d624d3a3",
  },
  {
    title: "User Management Uygulaması (Master — Details)",
    postId: "b730ad4a9760",
  },
]

const kanbanBoardMechanics = [
  {
    title: "Kanban Board Mini App -1 ",
    postId: "fb8b155f1bb3",
    postIdEnglish: "0d15c143ebe7",
  },
]

const storiesAIAppMechanics = [
  {
    title: "React ile AI Uygulamaların Geliştirimi — 1",
    postId: "8fc43c40d885",
  },
  {
    title: "React ile AI Uygulamaların Geliştirimi — 2",
    postId: "1476bf67d53a",
  },
  {
    title: "React ile AI Uygulamaların Geliştirimi — 3",
    postId: "33d338b719a8",
  },
  {
    title: "React ile AI Uygulamaların Geliştirimi — 4",
    postId: "e8888245a2ff",
  },
  {
    title: "React ile AI Uygulamaların Geliştirimi — 5",
    postId: "58b8660993f9",
  },
  {
    title: "React ile AI Uygulamaların Geliştirimi — 6",
    postId: "b71c9e772661",
    postIdEnglish: "691aa71222af",
  },
]

const storiesGroup = [
  {
    title: "Temel Mekanikler",
    storyPart: storiesBasicUIMechanics,
  },
  {
    title: "Hesap Makinesi Mekaniği",
    storyPart: storiesCalculatorMechanics,
  },
  {
    title: "Admin Tool Mekanikleri",
    storyPart: storiesAdminToolUIMechanics,
  },
  {
    title: "CRUD Mekanikleri",
    storyPart: storiesMiniAppMechanics,
  },
  {
    title: "AI App Mekanikleri",
    storyPart: storiesAIAppMechanics,
  },
  {
    title: "Kanban Board Mekanikleri",
    storyPart: kanbanBoardMechanics,
  },
]

export const context = {
  stories: flatStoryGroup(storiesGroup),
  linkMap: LinkMap,
  title: "React UI Mechanics",
  path: "react-ui-mechanics",
}

const ReactUIMechanicsPage = () => {
  return (
    <StoryGroup
      title={context.title}
      complex={true}
      eBooks={eBooks}
      stories={storiesGroup}
      linkMap={context.linkMap}
    />
  )
}

export default ReactUIMechanicsPage
